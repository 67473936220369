<template>
  <section class="view-catalog-b2b">
    <div class="view-catalog-b2b__intro">
      <div class="view-catalog-b2b__intro-content">
        <h2
          class="view-catalog-b2b__intro-title"
          v-html="$t('resto.b2b_title')"
        ></h2>

        <p
          class="view-catalog-b2b__intro-descr"
          v-html="$t('resto.b2b_descr')"
        ></p>
      </div>
    </div>

    <form
      class="view-catalog-b2b__body container"
      @submit.prevent>
      <forms-input
        class="view-catalog-b2b__code"
        :disabled="loading"
        :errors="errors"
        @iconClick="onValidate"
        icon-post-interactive
        icon-post="arrow-right"
        :placeholder="$t('resto.b2b_code_placeholder')"
        v-model="code"
      >{{ $t('resto.b2b_code_label') }}</forms-input>
    </form>
  </section>
</template>

<script>
import { mapState } from 'vuex'
import Config from '@/config'

export default {
  name: 'CatalogB2b',

  inject: [
    '$embed',
    '$localStorage'
  ],

  data: () => {
    return {
      code: '',
      loading: false,
      errors: []
    }
  },

  computed: {
    ...mapState({
      project: state => state.bootstrap.data
    })
  },

  methods: {
    async onValidate() {
      this.loading = true
      this.errors = []

      this.code = this.code?.trim() ?? ''

      if(this.code === '') {
        this.errors = [this.$t('resto.b2b_code_empty')]
        this.loading = false
        return
      }

      try {
        let res = await this.$embed.shop.submitClientReference({ reference: this.code })

        this.$localStorage.company = res

        let route = `${ window.location.origin }${ Config.publicPath }`
        route = route.replace(this.$basil.get(this.project, 'embed.id'),  res.embed_id)
        if(!route.includes(res.embed_id)) {
          route += res.embed_id
        }
        window.location = `${route}?invoice_client_id=${ res.id }`
      } catch(e) {
        $console.error(e)

        if(e.status === 404) {
          this.errors = [this.$t('resto.b2b_code_not_found')]
          return
        }

        this.$notification({
          title: this.$t('resto.b2b_not_handled_error'),
          type: 'error',
        })
      } finally {
        this.loading = false
      }
    }
  },

  created() {
    let company = this.$localStorage.company

    if(company && company.project_id === this.project.project_id) {
      let route = `${ window.location.origin }${ Config.publicPath }`
      route = route.replace(this.$basil.get(this.project, 'embed.id'),  company.embed_id)
      if(!route.includes(company.embed_id)) {
        route += company.embed_id
      }
      window.location = `${route}?invoice_client_id=${ company.id }`
        // window.location = `${ window.location.origin }${ Config.publicPath }${ company.embed_id }?invoice_client_id=${ company.id }`
    }
  }
}
</script>
